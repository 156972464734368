// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-how-it-works-revenue-by-company-js": () => import("./../src/templates/how-it-works-revenue-by-company.js" /* webpackChunkName: "component---src-templates-how-it-works-revenue-by-company-js" */),
  "component---src-templates-content-default-js": () => import("./../src/templates/content-default.js" /* webpackChunkName: "component---src-templates-content-default-js" */),
  "component---src-templates-how-it-works-process-js": () => import("./../src/templates/how-it-works-process.js" /* webpackChunkName: "component---src-templates-how-it-works-process-js" */),
  "component---src-templates-how-it-works-default-js": () => import("./../src/templates/how-it-works-default.js" /* webpackChunkName: "component---src-templates-how-it-works-default-js" */),
  "component---src-templates-downloads-default-js": () => import("./../src/templates/downloads-default.js" /* webpackChunkName: "component---src-templates-downloads-default-js" */),
  "component---src-templates-archive-default-js": () => import("./../src/templates/archive-default.js" /* webpackChunkName: "component---src-templates-archive-default-js" */),
  "component---src-templates-case-studies-template-js": () => import("./../src/templates/case-studies-template.js" /* webpackChunkName: "component---src-templates-case-studies-template-js" */),
  "component---src-templates-how-it-works-reconciliation-js": () => import("./../src/templates/how-it-works-reconciliation.js" /* webpackChunkName: "component---src-templates-how-it-works-reconciliation-js" */),
  "component---src-templates-offshore-region-js": () => import("./../src/templates/offshore-region.js" /* webpackChunkName: "component---src-templates-offshore-region-js" */),
  "component---src-templates-state-page-js": () => import("./../src/templates/state-page.js" /* webpackChunkName: "component---src-templates-state-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-explore-index-js": () => import("./../src/pages/explore/index.js" /* webpackChunkName: "component---src-pages-explore-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-query-data-index-js": () => import("./../src/pages/query-data/index.js" /* webpackChunkName: "component---src-pages-query-data-index-js" */),
  "component---src-pages-search-results-index-js": () => import("./../src/pages/search-results/index.js" /* webpackChunkName: "component---src-pages-search-results-index-js" */)
}

